/**
 * ==================================================
 * Sistema de planeación de recursos empresariales 
 * @author Enso-Learning
 * @copyright Copyright (c) 2022, Enso-Learning
 * @version 1.0 EDU_PLT
 * ==================================================
*/
import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import Swal from 'sweetalert2';
import styles from './CrearColegio.css';
import ConsultaAdmin from 'utils/ConsultaAdmin';
import Consulta from 'utils/Consulta';

const CrearColegio = () => {
    const [estado, setestado] = useState(0)
    const [colegios, setcolegios] = useState([])
    const [Curso, setCurso] = useState([])
    const [form, setform] = useState([])
    const User = useSelector((state) => state.user);


    var remaining = (form.Descripcion ? form.Descripcion.length : 0);
    remaining = 500 - remaining;

    const TraerCursos = async (id_colegio) =>{
        setestado(2);
        let Colegio = { d: 1, id: id_colegio };
        const respuesta = await Consulta(Colegio,'info_Cursos.php');
        setCurso(respuesta.data);
        
    }

    const GuardarDatos = (e) => {
        setform({
            ...form,
            [e.target.name]: e.target.value.trim()
        })
    }
    const Enviar = async (e) => {
        e.preventDefault();
        var Datos = {
            d: 1,
            Nombre: form.Nombre,
            id: 0,
            Telefono: form.Telefono,
            Info: form.Descripcion,
            Reseller: User.id,
            cupos: form.cupos
        }
        const consulta = await ConsultaAdmin (Datos,'Crud_admin.php');
        if (consulta.data.estado === false) {
            Swal.fire({
                icon: 'warning',
                title: '¡Alerta!',
                text: consulta.data.mensaje,
                confirmButtonText: 'Entendido'
            })
        } else if (consulta.data) {
            Swal.fire({
                icon: 'success',
                title: '¡Exitoso!',
                text: 'Colegio creado con éxito',
                confirmButtonText: 'Entendido'
            }).then((result) => {
                if (result.isConfirmed) {
                    setestado(1)
                }
            })
        }
    }
    useEffect(() => {
        const TrearDatos = async () => {
            let idCurso = { d: 2, reseller: User.id };
            const response = await ConsultaAdmin (idCurso,'Crud_admin.php');
            const data = response.data;
            setcolegios(data[0].id ? data : false);
        }
        TrearDatos();
    }, [estado, User]);
    return (
        <div className="col-md-12">
            <div className="row center">
                <div className="col-md-6">
                    <p className="card-text text-center shadow p-3 m-2 Areas pointer" onClick={() => setestado(0)}>Crear Colegio</p>
                </div>
                <div className="col-md-6">
                    <p className="card-text text-center shadow p-3 m-2 Areas pointer" onClick={() => setestado(1)}>Consultar Colegio</p>
                </div>
            </div>
            {estado === 0 ?
                <div className="row">
                    <div className="col-md-12 text-center">
                        <form onSubmit={Enviar}>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Nombre Colegio</label>
                                <input type="text" name='Nombre' className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={GuardarDatos} />
                                <div id="emailHelp" className="form-text">Agregue un nombre de la institución</div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label">Telefono</label>
                                        <input type="number" onChange={GuardarDatos} name='Telefono' className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label">Cupos</label>
                                        <input type="number" name='cupos' onChange={GuardarDatos} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Descripción </label><br />
                                    <div className={styles.container}>
                                        <textarea
                                            style={{ width: '100%' }}
                                            onChange={GuardarDatos}
                                            maxLength="500"
                                            placeholder="Escribe aquí..."
                                            name="Descripcion"
                                            cols="110" rows="10"
                                            id="Descripcion"
                                        />
                                        <div className={styles.charCount}>
                                            {remaining >= 0 ? `Caracteres restantes: ${remaining}` : 'Límite de caracteres excedido'}
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary">Cargar</button>
                        </form>
                    </div>
                </div>
                :
                null
            }
            {estado === 1 ?
                <div className="row">
                    <div className="col-md-12">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Nombre</th>
                                    <th scope="col">Fecha</th>
                                    <th scope="col">Cupos en uso</th>
                                    <th scope="col">Cupos max</th>
                                    <th scope="col">fecha vencimiento</th>
                                    <th scope="col">Estado</th>
                                    <th scope="col">Ver</th>
                                </tr>
                            </thead>
                            <tbody>
                                {colegios

                                    ? colegios.map((Colegio) =>
                                        <tr>
                                            <th scope="row">{Colegio.id}</th>
                                            <td>{Colegio.nombreC}</td>
                                            <td>{Colegio.fecha_creación}</td>
                                            <td>{Colegio.cupos}</td>
                                            <td>{Colegio.Cupos_max}</td>
                                            <td>{Colegio.fecha_vencimiento}</td>
                                            <td className='text-center'>
                                                {Colegio.pago === 1 ?
                                                    <span style={{ color: 'green' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                                        </svg>
                                                    </span>
                                                    :
                                                    <span style={{ color: 'red' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                        </svg>
                                                    </span>
                                                }
                                            </td>
                                            <td>
                                                <span style={{ cursor: 'pointer' }} onClick={() => TraerCursos(Colegio.id)} >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                                    </svg>
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                    : null
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                :
                null
            }
            {estado === 2 ?
                <div className="row">
                    <div className="col-md-12">
                        <table className="table">
                            <thead> 
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Curso</th>
                                    <th scope="col">Docente</th>
                                    <th scope="col">Estudiantes esperados</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Curso && Curso.length

                                    ? Curso.map((Curso) =>
                                        <tr>
                                            <th scope="row">{Curso.id}</th>
                                            <td>{Curso.Curso_Nu}</td>
                                            <td>{Curso.Nombres ? Curso.Nombres +' '+Curso.Apellidos : 'No asignado'}</td>
                                            <td>{Curso.Can_Est}</td>
                                        </tr>
                                    )
                                    : null
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                :
                null
            }
        </div>
    )
}
export default CrearColegio;